<template>
  <li class="approval-details-item">
    <label>{{ label }}</label>
    <span>{{ value }}</span>
  </li>
</template>

<script>
export default {
  name: 'ApprovalDetailItem',
  props: ['label', 'value'],
};
</script>

<style></style>
