<template>
  <el-card class="approval-content page-container">
    <el-form slot="header" size="small" :model="approval" :inline="true">
      <el-form-item label="工单状态" class="approval-status">
        <el-button type="button" v-for="btn in statusButton" :key="btn.value" @click="handleChangeStatus(btn.value)" :class="['status-button', approval.status === btn.value ? 'active' : '']">{{ btn.label }}</el-button>
      </el-form-item>
      <el-form-item label="工单名称">
        <el-input v-model="approval.name" clearable @clear="handleClear(approval)">
          <el-button slot="append" icon="el-icon-search" @click="handleSearch(approval)">查询</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="审批状态" v-if="false">
        <el-select v-model="approval.approvalStatus" clearable @change="handleChangeApprovalStatus(approval.approvalStatus)">
          <el-option v-for="item in itemStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table :data="approvalList" :height="tHeight">
      <el-table-column label="序号" type="index" align="center" width="60"></el-table-column>
      <el-table-column label="工单编号" prop="taskId" align="center" width="200">
        <template slot-scope="scope">
          <el-link type="primary" @click="showApproval(scope.row)">{{ scope.row.taskId }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="工单名称" prop="taskName" width="320" show-overflow-tooltip></el-table-column>
      <el-table-column label="工单状态" align="center" width="100">
        <template slot-scope="scope">
          <el-tag size="mini">{{ scope.row.taskStatus | tstatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="角色列表" prop="roles"></el-table-column>
      <el-table-column label="创建时间" width="180" align="center">
        <template slot-scope="scope">{{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss') }}</template>
      </el-table-column>
      <el-table-column label="申请人" prop="createUserName"></el-table-column>
      <el-table-column label="审批意见" prop="approvalOpinions"></el-table-column>
      <el-table-column label="审批时间" width="180">
        <template slot-scope="scope">{{ scope.row.approvalTime && $moment(scope.row.approvalTime).format('YYYY-MM-DD HH:mm:ss') }}</template>
      </el-table-column>
      <el-table-column label="审批人" prop="approvalUserName"></el-table-column>
      <el-table-column label="详情" prop="remark" v-if="false">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="throughApproval(3, scope.row.taskId)">通过</el-button>
          <el-button type="text" size="mini" @click="rejectApproval(4, scope.row.taskId)">驳回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-row class="pagination-bar">
      <el-pagination background layout="total, prev, pager, next" :current-page="page" :page-size="size" @current-change="handleCurrentChange" :total="total"></el-pagination>
    </el-row> -->
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" title="工单信息" :visible="dialog" :before-close="handleClose">
      <ul class="approval-details">
        <ApprovalItem label="工单名称" :value="approvalDetails.taskName" />
        <ApprovalItem label="工单编号" :value="approvalDetails.taskId" />
        <li>
          <label>工单状态</label>
          <el-tag size="mini">{{ approvalDetails.taskStatus | tstatus }}</el-tag>
        </li>
        <ApprovalItem label="审批人编号" :value="approvalDetails.approvalUserId" />
        <ApprovalItem label="审批人名称" :value="approvalDetails.approvalUserName" />
        <ApprovalItem label="创建人编号" :value="approvalDetails.createUserId" />
        <ApprovalItem label="创建人名称" :value="approvalDetails.createUserName" />
        <li>
          <label>工单描述</label>
          <span>{{ approvalDetails.taskDescription }}</span>
        </li>
        <li>
          <label>备注</label>
          <span>{{ approvalDetails.remark }}</span>
        </li>
        <li>
          <label>审批意见</label>
          <span>{{ approvalDetails.approvalOpinions }}</span>
        </li>
      </ul>
      <el-row style="padding: 20px">
        <el-button type="primary" size="mini" @click="throughApproval(3, approvalDetails.taskId)">通过</el-button>
        <el-button type="danger" size="mini" @click="rejectApproval(4, approvalDetails.taskId)">驳回</el-button>
      </el-row>
    </el-dialog>
  </el-card>
</template>

<script>
import ApprovalItem from './components/ApprovalItem.vue';
import PaginationBar from '@/components/Pagination/index.vue';
import { approvalCenterList, submitApproval } from '@/api/accraditation.js';
import statusInclude from '@/utils/statusCode';
import sleep from '@/utils/sleep';
export default {
  name: 'Approval',
  components: {
    ApprovalItem,
    PaginationBar,
  },
  data() {
    return {
      tHeight: 0,
      dialog: false,
      approvalList: [],
      statusButton: [
        {
          label: '待办',
          value: 0,
        },
        {
          label: '已办',
          value: 1,
        },
      ],
      itemStatus: [
        {
          value: 1,
          label: '待审批',
        },
        {
          value: 2,
          label: '审批中',
        },
        {
          value: 3,
          label: '审批通过',
        },
        {
          value: 4,
          label: '审批驳回',
        },
        {
          value: 5,
          label: '审批完成',
        },
      ],
      approval: {
        name: '',
        approvalStatus: '',
        status: 0,
      },
      approvalDetails: {},
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
    };
  },
  created() {
    this.getApprovalList({ ...this.tableConfig, ...this.approval });
  },
  methods: {
    //修改工单状态
    handleChangeStatus(status) {
      this.approval.status = status;
      this.handleSearch(this.approval);
    },
    handleChangeApprovalStatus(approvalStatus) {
      if (approvalStatus === 1) {
        this.approval.status = 0;
      }
    },
    handleClear() {
      const { name, approvalStatus, status } = params;
      this.getApprovalList({ ...this.tableConfig, ...this.approval });
    },
    //查询个工单信息
    handleSearch(params) {
      this.getApprovalList({ ...this.tableConfig, ...params });
    },
    showApproval(approval) {
      this.approvalDetails = approval;
      this.dialog = true;
    },

    handleClose() {
      this.dialog = false;
    },

    //翻页
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getApprovalList({ ...this.tableConfig, ...this.approval });
    },

    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },
    //获取审批列表
    async getApprovalList({ page = 1, size = 13, name = '', approvalStatus = '', status = '' }) {
      const { code, data, reason } = await approvalCenterList(page, size, name, approvalStatus, status);
      if (!statusInclude(code)) return this.$message.error(`请求失败${reason}`);
      this.approvalList = data.records;
      this.tableConfig.total = data.total;
    },
    throughApproval(approvalStatus, taskId) {
      this.submitApproval(approvalStatus, taskId);
    },
    rejectApproval(approvalStatus, taskId) {
      this.$prompt('审批意见', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{4,20}$/,
        inputErrorMessage: '请输入4~20字审批意见',
      })
        .then(({ value }) => {
          this.submitApproval(approvalStatus, taskId, value);
        })
        .catch(() => {
          this.$message({ type: 'info', message: '取消审批' });
        });
    },
    async getApprovalTaskDetail(approvalId) {
      const { code, data, reason } = await getApprovalTaskDetail(approvalId);
      if (!statusInclude(code)) return this, $message.error(`请求失败${reason}`);
      this.approvalDetails = data;
    },
    async submitApproval(approvalStatus, taskId, approvalOpinions = '') {
      const { code, reason } = await submitApproval({ approvalStatus, taskId, approvalOpinions });
      if (!statusInclude(code)) return this.$messasge.error(`请求失败：${reason}`);
      this.$message({ type: 'success', message: '请求成功' });
      this.dialog = false;
      this.getApprovalList({ ...this.tableConfig, ...this.approval });
    },
  },
};
</script>
